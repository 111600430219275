<template>
    <div v-if="rowsDataTable.length>0">
        {{dataFils}}
         <table class="table-elements">
                <thead>
                    <tr v-for="(rowTable,index) in rowsDataTable" :key="index">
                        <th>{{rowTable.name}}</th>
                        <th>{{rowTable.value}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="dataColums.length>0">
                    <tr v-for="(commision,index)  in  dataColums" :key="index"  
                    :style="[(index%2==0)? {'background': '#FFF'}:{'background': '#efefef' }]">
                        <th :id="idTableUse.colums">{{commision.minimum_range}} - {{commision.maximum_range}} </th>
                        <th :id="typeMethod=='Importe Fijo'? `${idTableUse.typeScheme.payment}_${index}`: `${idTableUse.typeScheme.percentage}_${index}`"><label v-if="typeMethod=='Importe Fijo'">$</label> {{commision.value}}<label v-if="typeMethod=='Porcentaje'">%</label></th>
                        <th><button style="outline:0" @click="removeScheme(index)" :id="idTableUse.buttonDelete+'_'+index"><img height="25" width="25" src="@/assets/eliminar.svg"></button></th>
                    </tr>
                </tbody>
            </table>
    </div>
</template>

<script>
import { ref } from 'vue-demi';
import Swal from 'sweetalert2';
import('@/assets/css/referredperson.css');
import('@/assets/css/stylesheet.css');
import('@/assets/css/referredperson.css');
export default {
    name:"TableComissions",
    props:{
        columsDataTable:{
            type:Array,
        },
        rowsDataTable:{
            type:Array
        },
        typeMethod:{
            type:String
        },
        idTableUse:{
            type:Object
        }
    },
    setup(props){
        let dataRows=ref([]);
        let dataColums=ref([]);
        
        async function createTable(){
            dataColums.value=await props.columsDataTable;
            dataColums.value=await props.columsDataTable;
        }
        function resetTable(){
            dataColums.value=[];
        }
        function removeScheme(index){
            Swal.fire({
                    title: "Aviso",
                    text: "¿Desea eliminar el producto?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    confirmButtonColor: '#FEB72B',
                    cancelButtonColor: '#FFFFFF'
            }).then((result) => {
                if (result.value) {
                        dataColums.value.splice(index,1);
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: 'Producto eliminado.'
                        })       
                    }
                });
            setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_delete', 'button_cancel_delete') 
        }
        function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
        }
        return{
            //variables
            dataRows,
            dataColums,
            //Funciones
            createTable,    
            removeScheme,
            resetTable
        }
    },
}
</script>


<style scoped>
.swal2-actions{
    direction: rtl ; 
}
</style>
<style>
#button_cancel_delete{
    color: #810042;
    border: 1px solid #810042;
}

.table-elements{
    text-align: left;
    width: 100%;    
}
.table-elements thead{
    border-bottom:3px solid #ddd;
}
.table-elements thead th,
.table-elements tbody th{
    padding:10px ;
}

.table-elements tbody tr:nth-child(2n) {
  background: #FFC001;
}
</style>

<style scoped>
.swal2-actions{
    direction: rtl ; 
}
</style>