<template>
  <Loading :isLoading="isLoading"/>
    <div v-if="rulesLoaded">
        <div v-show="_hideTableonSave">
        <div class="w-full">
            <h2 class="text-center font-bold text-3xl" style="color:#810042">Capturar Esquema de Bono Individual por Producto</h2>
        </div>

        <div class="sm:w-full  lg:w-3/5 2xl:w-2/5  md:m-auto pt-10">
            <p>Nombre del Esquema de Bono:</p>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText"
            id="input_bonus_scheme_name_individual_bonus_scheme"
            v-model="v$.name.$model" @blur="v$.name.$validate"
            :class="{'invalid-form':v$.name.$error, 'valid-form':!v$.name.$invalid}">
            
            <div v-for="(error,index) of v$.name.$errors" :key="index">
              <div class="errors">{{ error.$message }}</div>
            </div>
        </div>

        <div class="sm:w-full lg:w-3/5 2xl:w-2/5 md:m-auto pt-10">
            <h4 class="text-xl">Añadir Productos</h4>
            <p>Producto:</p>
            <select class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText"
            id="select_product_individual_bonus_scheme"
            v-model="v$.product_name.$model" @blur="v$.product_name.$validate"
            :class="{'invalid-form':v$.product_name.$error, 'valid-form':!v$.product_name.$invalid}"> 
                <option value="" selected disabled>Seleccione un producto</option>
                <option value="Ahorro">Ahorro</option>
                <option value="Inscripcion asistida">Inscripcion asistida</option>
            </select>

            <div v-for="(error,index) of v$.product_name.$errors" :key="index">
              <div class="errors">{{ error.$message }}</div>
            </div>
        </div>

        <div class="sm:w-full lg:w-3/5 2xl:w-2/5 mt-10 md:m-auto md:mt-10 ">
            <p>Frecuencia de pago:</p>
            <select class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText"
            id="select_payment_frequencyl_bonus_scheme"
            v-model="v$.payment_frequency.$model" @blur="v$.payment_frequency.$validate"
            :class="{'invalid-form':v$.payment_frequency.$error, 'valid-form':!v$.payment_frequency.$invalid}"> 
                <option value="" disabled selected>Seleccione una frecuencia de pago.</option>
                <option value="quincenal">Quincenal</option>
                <option value="mensual">Mensual</option>
            </select>

            <div v-for="(error,index) of v$.payment_frequency.$errors" :key="index">
              <div class="errors">{{ error.$message }}</div>
            </div>
            
        </div>

        <div class="sm:w-full lg:w-3/5 2xl:w-2/5 md:m-auto md:mt-10">
            <ChooseButton
                ref="chooseButtonComponent"
                :buttons="choosethefrequencypayment"
                v-on:choose-button="selectChoosePaymentmethod($event)"
                label="Elige como se va a pagar:"/>

            <div v-for="(error,index) of v$.comission_type.$errors" :key="index">
              <div class="errors">{{ error.$message }}</div>
            </div>

        </div>

        <div class="md:block  md:w-full lg:w-3/5 2xl:w-2/5 flex-nowrap md:m-auto md:mt-10">
            <div class="flex mb-2 ">
                <p class="w-5/6">Rango de solicitudes:</p>
                <p class="w-3/5 display-none " v-if="v$.comission_type.$model=='fijo'">Ingresar Importe:</p>
                <p class="w-3/5 display-none" v-if="v$.comission_type.$model=='porcentaje'">Ingresar Porcentaje:</p>
            </div>
            <div class="w-full md:flex">
                <div class="mx-5 pt-3">
                    <label class="text-center md:text-left w-full">
                        <p>De</p>
                    </label>
                </div>
                <div class="w-full md:w-1/3 ">
                    <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText "
                    id="input_start_request_range_bonus_scheme" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    v-model=" saleBonusDchemaDetailsAttributes$.minimum_range.$model"
                    :class="{'invalid-form':saleBonusDchemaDetailsAttributes$.minimum_range.$error, 'valid-form':!saleBonusDchemaDetailsAttributes$.minimum_range.$invalid}">

                    <div class="display-block-error">
                        <div v-for="(error,index) of saleBonusDchemaDetailsAttributes$.minimum_range.$errors" :key="index">
                            <div class="errors">{{ error.$message }}</div>
                        </div>
                    </div>

                </div>
                <div class="mx-5 pt-3">
                    <label class="text-center md:text-left w-full">
                        <p>A</p>
                    </label>
                </div>
                <div class="w-full md:w-1/3">
                   <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText"
                   id="input_end_request_range_bonus_scheme" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    v-model=" saleBonusDchemaDetailsAttributes$.maximum_range.$model"
                    :class="{'invalid-form':saleBonusDchemaDetailsAttributes$.maximum_range.$error, 'valid-form':!saleBonusDchemaDetailsAttributes$.maximum_range.$invalid}"> 
                  
                   <div class="display-block-error">
                        <div v-for="(error,index) of saleBonusDchemaDetailsAttributes$.maximum_range.$errors" :key="index">
                            <div class="errors">{{ error.$message }}</div>
                        </div>
                    </div>
                </div>
                
                <div class="w-full md:w-1/2 md:mx-5">
                    <div class="py-5 md:py-0 shadow-md border-2 rounded-lg" v-if="v$.comission_type.$model=='fijo'"
                        :class="{'invalid-form':saleBonusDchemaDetailsAttributes$.value.$error && _percentageAndamount, 'valid-form':!saleBonusDchemaDetailsAttributes$.value.$invalid && _percentageAndamount}">
                        <p class="w-1/2 display-block pt-10">Ingresar Importe:</p>
                        <div class=" py-2 w-full px-5 flex" >
                            <span style="color: #CABBBB;">$</span>
                            <input type="text" style="outline:0;" class="mx-2 w-full" v-money="money" :maxLength="11"
                            oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            id="input_fixed_amount_bonus_scheme"
                            v-model=" saleBonusDchemaDetailsAttributes$.value.$model"
                             @blur="_percentageAndamount=true">
                        </div>
                    </div>
                    <div class="py-5 md:py-0 shadow-md border-2 rounded-lg" v-if="v$.comission_type.$model=='porcentaje'"
                    :class="{'invalid-form':saleBonusDchemaDetailsAttributes$.value.$error && _percentageAndamount, 'valid-form':!saleBonusDchemaDetailsAttributes$.value.$invalid && _percentageAndamount}">
                        <p class="w-1/2 display-block pt-10">Ingresar Porcentaje:</p>
                        <div class=" py-2 w-full px-5 flex" >
                            <span style="color: #CABBBB;">%</span>
                            <input type="text" style="outline:0;" class="mx-2 w-full   outline-none focus:border-cherryColor" id="input_percentage_bonus_scheme" :maxlength="6" 
                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode==46)"
                            @blur="_percentageAndamount=true" 
                            v-model=" saleBonusDchemaDetailsAttributes$.value.$model">
                        </div>
                    </div>

                    <div v-if="v$.comission_type.$model.length>0 && _percentageAndamount">
                        <div v-for="(error,index) of saleBonusDchemaDetailsAttributes$.value.$errors" :key="index">
                            <div class="errors">{{ error.$message }}</div>
                        </div>
                    </div>
                </div>
                <div class="top-12 h-20 w-20">
                    <button @click="assignTable()" class="button-plus" style="outline:0;" id="icon_add_new_product_bonus_scheme"><img src="@/assets/more.svg"  width="50" height="50"></button>
                </div>
            </div>
            
        </div>

        <div class="md:block  md:w-full  lg:w-3/5 2xl:w-2/5  flex-nowrap md:m-auto md:mt-10 display-none-error">

            <div>
                <div v-for="(error,index) of saleBonusDchemaDetailsAttributes$.minimum_range.$errors" :key="index">
                    <div class="errors">{{ error.$message }}</div>
                </div>
            </div>


            <div>
                <div v-for="(error,index) of saleBonusDchemaDetailsAttributes$.maximum_range.$errors" :key="index">
                    <div class="errors">{{ error.$message }}</div>
                </div>
            </div>
        </div>

        <div class="md:w-full  lg:w-3/5 2xl:w-2/5  md:m-auto md:mt-10 " >
            <p class="font-medium font-bold text-xl" v-if="bonusSchemeDetail.length>0">Detalles del Esquema de Bono.</p>
            <p class="font-medium font-medium my-3" id="label_scheme_name_bonus_scheme" v-if="bonusSchemeDetail.length>0">Nombre del Esquema: <label> {{v$.name.$model}} </label> </p>
            <p class="font-medium font-medium my-3" id="label_chosen product_bonus_scheme" v-if="bonusSchemeDetail.length>0">Producto elegido: <label> {{v$.product_name.$model}} </label> </p>
                <TableComissions ref="tableComissions" :columsDataTable="bonusSchemeDetail" :rowsDataTable="bonusSchemeRows" :typeMethod="v$.comission_type.$model" :idTableUse="idTableUse"  />
            
        </div>

        <div class="md:w-1/2  lg:w-3/5 xl:w-2/5  md:m-auto mt-20 ">
            <button class="shadow-md rounded-lg w-full mx-auto px-5 py-5 mt-2  btn-save display-none" id="button_save_bonus_scheme" @click="saveBondRegistration()">Guardar</button>
        </div>
        </div>
    </div>
</template>
<script>
import CommissionScheme from "@/classes/CommissionScheme.js"
import ChooseButton from "@/components/Forms/Buttons/ChooseButton.vue";
import TableComissions from "@/components/CommissionScheme/TableComissions/TableComissions.vue"
import Loading from '@/components/Loading/VueLoading.vue'
import { onBeforeMount, onMounted, reactive, ref } from 'vue-demi';
import useVuelidate from "@vuelidate/core";
import {requiredIf } from "vuelidate/lib/validators";
import Swal from 'sweetalert2';
import { helpers } from '@vuelidate/validators';
import { VMoney } from "v-money";
import validations from '@/helpers/validations';
import {getTokenDecoden} from "@/helpers/tokenauth";
export default {
    name:"CaptureBonusScheme",
    components:{
        ChooseButton,
        TableComissions,
        Loading
    },
    directives: { money: VMoney },
    setup(){
        let _commissionScheme=ref(new CommissionScheme());
        let rulesValidations=ref([]);
        let rulesLoaded=ref(false);
        let isLoading=ref(false);
        let _hideTableonSave=ref(true);
        const idTableUse=ref({
            colums:"label_range_bonus_scheme",buttonDelete:"icon_delete_product_bonus_scheme",
                typeScheme:{
                    payment:"label_payment_type_added_bonus_scheme",percentage:"label_percentage_added_bonus_scheme"
                }
            })
        let choosethefrequencypayment=[
            { id: "button_fixed_amount_commission_scheme", value: "fijo", text: "Importe Fijo"},
            { id: "button_percentage_commission_scheme", value: "porcentaje", text: "Porcentaje" }];
        let bonusSchemeDetail=ref([]);
        const bonusSchemeRows=ref([{name:"Rango",value:"Importe"}]);
        let money=ref( {
            decimal: ".",
            thousands: ",",
            prefix: "",
            suffix: "",
            precision: 2,
            masked: false /* doesn't work with directive */,
        });
        let _percentageAndamount=ref(false);
        let lastMaxRank=ref(null);
        
        const chooseButtonComponent=ref(null);
        const tableComissions=ref(null);
        let _commissionSchemeRuleValidations=reactive({
            name:{
                required:helpers.withMessage("El campo Nombre del Esquema de Bono es requerido.",requiredIf(function(){return  rulesValidations.value.capture_product_bonus_cheme.name.required})),
                minlengthValue:helpers.withMessage(()=>{return "El campo Nombre del Esquema de Bono debe contener mínimo "+ rulesValidations.value.capture_product_bonus_cheme.name.minLength+" caracteres."},(value)=>{return rulesValidations.value.capture_product_bonus_cheme.name.minLength<=value.length}),
                maxlenghvalue:helpers.withMessage(()=>{return "El campo Nombre del Esquema de Bono debe contener maximo "+ rulesValidations.value.capture_product_bonus_cheme.name.maxLength+" caracteres."},(value)=>{return rulesValidations.value.commission_scheme.name_commission_scheme.maxLength>=value.length}),
                specialcharacteres:helpers.withMessage("El campo Nombre del Esquema de Bono no permite caracteres especiales",(value)=>new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/g).test(value))
            },
            product_name:{
                required:helpers.withMessage("El campo Producto es requerido.",requiredIf(function(){return  rulesValidations.value.capture_product_bonus_cheme.product_name.required})),
            },
            payment_frequency:{
                required:helpers.withMessage("El campo Frecuencia de pago es requerido.",requiredIf(function(){return  rulesValidations.value.capture_product_bonus_cheme.payment_frequency.required})),
            },
            comission_type:{
                required:helpers.withMessage("El campo Elige como se va a pagar es requerido.",requiredIf(function(){return  rulesValidations.value.capture_product_bonus_cheme.comission_type.required})),
            } 
        });
        let _saleBonusDchemaDetailsAttributesRuleValidations=reactive({
            value:{
                required:helpers.withMessage(()=>{
                    if(_commissionScheme.value.bond_registration.comission_type=="porcentaje"){
                         return "El campo porcentaje es requerido.";
                    }else{
                        return "El campo Importe es requerido.";
                    }
                },
                function(value){
                    if(rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.required==true){
                        if(_commissionScheme.value.bond_registration.comission_type=="fijo" && value!=0){return true}
                        if(_commissionScheme.value.bond_registration.comission_type=="porcentaje" && value!=0 && value!= null){return true}
                    }
                    if(rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.required==false){return false;}
                }),
                greaterThanHundred:helpers.withMessage(()=>{
                    if(parseFloat(_commissionScheme.value.sale_bonus_schema_details_attributes.value)>100 && _commissionScheme.value.bond_registration.comission_type=="porcentaje"){
                        return "El campo porcentaje debe ser menor o igual a 100.";
                    }else{
                        return null
                    }
                },function(value){
                    if(value <=100  && _commissionScheme.value.bond_registration.comission_type=="porcentaje"){
                        {return value<=100 && _commissionScheme.value.bond_registration.comission_type=="porcentaje"}
                    }
                    if(parseFloat(fomartNumber(value))>0 && _commissionScheme.value.bond_registration.comission_type=="fijo"){return true}
                }),
                valueTwoNumber:helpers.withMessage("Después del punto solo se admiten 2 decimales.",(value)=> {
                    if(_commissionScheme.value.bond_registration.comission_type=="porcentaje" && value!=""){
                        return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(value)
                    }
                    else return true;
                }),
                minimum_amount:helpers.withMessage(()=>{
                    return `El monto mínimo debe ser mayor a ${rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.minimum_amount}`
                },(value)=>{
                    if(parseFloat(value)>=parseFloat(rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.minimum_amount)){
                        return true;
                    }
                })
            },
            minimum_range:{
                required:helpers.withMessage("El rango mínimo (DE) es requerido.",requiredIf(function(){return  rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.minimum_range.required})),
                minLength:helpers.withMessage("El rango mínimo (DE) solo permite hasta 4 digitos.",(value)=> value.toString().length<=rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.minimum_range.minLength?true:false),
                isDiferentTovalue:helpers.withMessage("El rango mínimo (DE) debe ser menor o igual al campo A",(value)=> parseInt(value)<=parseInt(_commissionScheme.value.sale_bonus_schema_details_attributes.maximum_range)?true:false),
                greateThatZero:helpers.withMessage("El rango mínimo (DE) debe ser mayor que 0",(value)=> parseInt(value)>0?true:false),
                wholenumberonly:helpers.withMessage("El rango mínimo (DE) solo admite números enteros.",(value)=>{return new RegExp(/^\d+$/g).test(value.toString())})
            },
            maximum_range:{
                required:helpers.withMessage("El rango máximo (A) es requerido.",requiredIf(function(){return  rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.maximum_range.required})),
                minLength:helpers.withMessage("El rango máximo (A) solo permite hasta 4 digitos.",(value)=> value.toString().length<=rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.maximum_range.minLength?true:false),
                isDiferentTovalue:helpers.withMessage("El rango máximo (A) debe ser mayor o igual al campo DE.",(value)=> parseInt(value)>=parseInt(_commissionScheme.value.sale_bonus_schema_details_attributes.minimum_range)?true:false),
                greateThatZero:helpers.withMessage("El rango máximo (A) debe ser mayor que 0.",(value)=> parseInt(value)>0?true:false),
                wholenumberonly:helpers.withMessage("El rango máximo (A) solo admite números enteros.",(value)=>{return new RegExp(/^\d+$/g).test(value.toString())})
            }, 
        });
        
        let v$=useVuelidate(_commissionSchemeRuleValidations,_commissionScheme.value.bond_registration);
        let saleBonusDchemaDetailsAttributes$=useVuelidate(_saleBonusDchemaDetailsAttributesRuleValidations,_commissionScheme.value.sale_bonus_schema_details_attributes)
        onMounted(()=>{ 
        });
        onBeforeMount(()=>{
            getRulesValidations();
        });
        async function getRulesValidations(){
            const response=await validations.getStructureValidations("/json/structureValidationscommissionscheme.json");
            rulesValidations.value = response.data;
            rulesLoaded.value=true;
        }
        async function assignTable(){
            _percentageAndamount.value=true;
            let searshresponse=null
            let repiter=false;
            let isLessNumber= false;
            this.saleBonusDchemaDetailsAttributes$.$validate()
            if(await this.v$.comission_type.$validate() && !this.saleBonusDchemaDetailsAttributes$.$error){
                this.saleBonusDchemaDetailsAttributes$.$validate();
                let _sale_bonus_schema_details_attributes=new CommissionScheme();
                _sale_bonus_schema_details_attributes.sale_bonus_schema_details_attributes.value=_commissionScheme.value.sale_bonus_schema_details_attributes.value;
                _sale_bonus_schema_details_attributes.sale_bonus_schema_details_attributes.minimum_range=_commissionScheme.value.sale_bonus_schema_details_attributes.minimum_range;
                _sale_bonus_schema_details_attributes.sale_bonus_schema_details_attributes.maximum_range=_commissionScheme.value.sale_bonus_schema_details_attributes.maximum_range;
                _sale_bonus_schema_details_attributes.sale_bonus_schema_details_attributes.users_id=(getTokenDecoden()).id;
                 searshresponse= bonusSchemeDetail.value.find(element=>{return _commissionScheme.value.sale_bonus_schema_details_attributes.minimum_range>=element.minimum_range && _commissionScheme.value.sale_bonus_schema_details_attributes.minimum_range<=element.maximum_range?true:false});
                  if( bonusSchemeDetail.value.length>0){
                    bonusSchemeDetail.value.forEach(element=>{
                        if(lastMaxRank.value==null){
                            lastMaxRank.value=element
                        }
                        if(element.minimum_range>lastMaxRank.value.maximum_range){
                            lastMaxRank.value=element
                        }
                    });
                    isLessNumber=parseFloat(_commissionScheme.value.sale_bonus_schema_details_attributes.value)<parseFloat(lastMaxRank.value.value)
                    repiter=_commissionScheme.value.sale_bonus_schema_details_attributes.minimum_range>lastMaxRank.value.maximum_range && lastMaxRank.value.maximum_range>_commissionScheme.value.sale_bonus_schema_details_attributes.maximum_range
                }
                
                searshresponse=searshresponse==null?false:searshresponse
                console.log("Por que el modelo no es valido "+searshresponse+"::::"+repiter)
                if(searshresponse || repiter || isLessNumber ){
                    Swal.fire({
                        title: "Aviso",
                        text: "El registro insertado ya existe, o su importe tiene que ser mayor, agregue otro rango para continuar.",
                        icon: "warning",
                        confirmButtonText: "Ok",
                        confirmButtonColor: '#FEB72B',
                    });
                }
                else{
                    rulesValidations.value.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.minimum_amount=_commissionScheme.value.sale_bonus_schema_details_attributes.value;
                    bonusSchemeDetail.value.push(_sale_bonus_schema_details_attributes.sale_bonus_schema_details_attributes);
                    tableComissions.value.createTable();
                    _percentageAndamount.value=false;
                }
                //Limpiar campos
                _commissionScheme.value.sale_bonus_schema_details_attributes.value="";
                _commissionScheme.value.sale_bonus_schema_details_attributes.minimum_range="";
                _commissionScheme.value.sale_bonus_schema_details_attributes.maximum_range="";
                //Resetiar las validaciones de los campos
                this.saleBonusDchemaDetailsAttributes$.$reset();
            }else{
                Swal.fire({
                    title: "Aviso",
                    text: "Favor de capturar los campos solicitados.",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                });
            }
            
        }
        function selectChoosePaymentmethod(event){
            if( _commissionScheme.value.bond_registration.comission_type=="" || _commissionScheme.value.bond_registration.comission_type==null){
               _commissionScheme.value.bond_registration.comission_type=event;
            }else{
                Swal.fire({
                    title: "Aviso",
                    text: '¿Desea elegir otro método de pago para el esquema? Si la respuesta es "SI" se borrara lo ya registrado.',
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "SI",
                    cancelButtonText: "NO",
                    confirmButtonColor: '#FEB72B',
                    cancelButtonColor: '#FFFFFF'
                }).then((result) => {
                    if (result.value) {
                        _percentageAndamount.value=false;
                        _commissionScheme.value.bond_registration.comission_type=event;
                        _commissionScheme.value.sale_bonus_schema_details_attributes.value=null;
                        bonusSchemeDetail.value=[];
                        tableComissions.value.resetTable();
                        this.rulesValidations.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.minimum_amount=0;
                    }
                   else{
                        chooseButtonComponent.value.changeDontbutton(_commissionScheme.value.bond_registration.comission_type)
                    }
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_delete', 'button_cancel_delete')
            }
            
        }
        function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
        }
        async function saveBondRegistration(){
            this.v$.$validate();
            if(!this.v$.$error && bonusSchemeDetail.value.length>0){
            _hideTableonSave.value=false;
                _commissionScheme.value.bond_registration.users_id=(getTokenDecoden()).id;
                _commissionScheme.value.bond_registration.token_auth=sessionStorage.getItem("login")
                bonusSchemeDetail.value.forEach(element => {
                    element.value=parseInt(((fomartNumber(element.value))).toString());
                });
                _commissionScheme.value.bond_registration.sale_bonus_schema_details_attributes=Object.assign({},  bonusSchemeDetail.value);
                    const response=await _commissionScheme.value.save( _commissionScheme.value.bond_registration,`/${process.env.VUE_APP_BUSINESS_FONCABSA}/bonus_schema/register`);
                    if(response.data.response.has_errors){
                        isLoading.value = false;        
                        bonusSchemeDetail.value.forEach(element => {
                            element.value=((parseInt(element.value))/100);
                        });
                         let messages="";
                            response.data.errors.forEach(element => {
                                 if(element=="Esquema de bonos ya existe"){
                                     messages+="<strong>■ El nombre del esquema de bono ya existe, ingresé otro nombre de esquema para continuar. </strong><br>"
                                 }
                                 else{
                                    messages+="<strong>■ "+element+".</strong><br>"
                                 }
                            // messages+="<strong>■ "+element+".</strong><br>"
                        });
                        Swal.fire({
                            title: "Aviso",
                            html:messages,
                            // response.data.response.message+"<br>"
                            icon: "warning",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#FEB72B'
                        }); 
                        _hideTableonSave.value=true;
                        return;
                    }else{
                       Swal.fire({
                            title: "Esquema de bono individual registrado correctamente.",
                            // text: response.data.response.message,
                            // text:"Registro existo.",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#FEB72B'
                        }); 
                    }
                _hideTableonSave.value=true;
                _percentageAndamount.value=false;
                _commissionScheme.value.bond_registration.token_auth="";
                _commissionScheme.value.bond_registration.name="";
                _commissionScheme.value.bond_registration.product_name="";
                _commissionScheme.value.bond_registration.comission_type="";
                _commissionScheme.value.bond_registration.users_id="";
                _commissionScheme.value.bond_registration.payment_frequency="";
                _commissionScheme.value.bond_registration.sale_bonus_schema_details_attributes={}
                bonusSchemeDetail.value=[];  
                tableComissions.value.resetTable()
                this.saleBonusDchemaDetailsAttributes$.$reset();
                this.v$.$reset();
            }else{
                Swal.fire({
                    title: "Aviso",
                    text: "Favor de completar los campos solicitados.",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.value) {
                       if(bonusSchemeDetail.value.length==0){
                            Swal.fire({
                                title: "Aviso",
                                text: "Favor de ingresar la información requerida en detalle del Esquema de Bono.",
                                icon: "warning",
                                confirmButtonColor: '#FEB72B',
                                confirmButtonText: "Ok",
                            });
                       }
                    }
                });
            }
        }
         function fomartNumber(value){
            value=value.toString();
            if (new RegExp(/[\.,]/g).test(value) == true) {
                value = value.replace(
                    /[\.,]/g,
                    ""
                );
            }
            return value;
        }
        return{
            v$,
            money,
            saleBonusDchemaDetailsAttributes$,
            rulesLoaded,
            choosethefrequencypayment,
            selectChoosePaymentmethod,
            saveBondRegistration,
            assignTable,
            //Referencias
            bonusSchemeDetail,
            bonusSchemeRows,
            chooseButtonComponent,
            tableComissions,
            _commissionScheme,
            isLoading,
            idTableUse,
            _percentageAndamount,
            _hideTableonSave,
            rulesValidations
        }
    },
    watch:{
        "saleBonusDchemaDetailsAttributes$.value.$model":function(){
            if(this.v$.comission_type.$model=="porcentaje" && this._commissionScheme.sale_bonus_schema_details_attributes.value!=null){
                if (new RegExp(/^[a-zA-ZÄ-Ÿä-ÿ]+$/g).test(this.saleBonusDchemaDetailsAttributes$.value.$model) == true) {
                    this._commissionScheme.sale_bonus_schema_details_attributes.value= this._commissionScheme.sale_bonus_schema_details_attributes.value.replace(
                    /[a-zA-ZÄ-Ÿä-ÿ]+/,"");
                }
            }
        },
        "bonusSchemeDetail":{
            handler(data){
                data.forEach(element=>{
                    this.rulesValidations.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.minimum_amount=0
                    if(parseFloat(element.value)>=parseFloat(this.rulesValidations.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.minimum_amount)){
                        this.rulesValidations.capture_product_bonus_cheme.sale_bonus_schema_details_attributes.value.minimum_amount=element.value;
                    }
                });
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.btn-save{
  background: #FFC001;
  color: white;
  font-size: 16px;
  font-weight: 400;
  transition: all 300ms;
}
.btn-save:hover{
  background: #f1b501;
}
.button-plus{
    width: 50px;
    height: 50px;
    transition: all 300ms;
}
.button-plus:hover{
    width: 55px;
    height: 55px;
}
@media only screen and (max-width: 640px) { 
  .display-none{
      display: none;
  }
}
@media only screen and (min-width: 640px) { 
  .display-block{
      display: none;
  }
}
@media only screen and (max-width: 768px) { 
  .display-none-error{
      display: none;
  }
}
@media only screen and (min-width: 768px) { 
  .display-block-error{
      display: none;
  }
}
.swal2-actions{
    direction: rtl;
}
</style>